<template lang="pug">
  layout-base
    router-view
</template>

<script>
import LayoutBase from '@components/layouts/Base'

export default {
  name: 'account',
  components: {
    LayoutBase,
  },
}
</script>
